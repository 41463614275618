import Axios, { AxiosError } from 'axios';
import { toast } from 'react-hot-toast';

import { API_URL } from '~/config';

export const axios = Axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (err: AxiosError) => {
    console.log('called:', err);
    if (err?.response?.status === 409) {
      console.log('refreshing,', err.response);
      const { status } = await axios.get('/user/refresh');
      console.log('here:', status);
      if (status === 200) {
        const config = err.config;
        if (config) return axios(config);
      }
    }
    const data = err.response?.data as { message: string };
    const message = data?.message ?? 'Something went Wrong';
    toast.error(message);
    return err.response;
  },
);
