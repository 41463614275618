import { LINKEDIN_CLIENT_ID, LINKEDIN_REDIRECT_URI } from '~/config';
// r_ads_reporting
// Retrieve reporting for your advertising accounts
// r_liteprofile
// Use your name and photo
// r_organization_social
// Retrieve your organization's posts, comments, reactions, and other engagement data
// rw_organization_admin
// Manage your organization's pages and retrieve reporting data
// w_member_social
// Create, modify, and delete posts, comments, and reactions on your behalf
// r_ads
// Retrieve your advertising accounts
// r_emailaddress
// Use the primary email address associated with your LinkedIn account
// w_organization_social
// Create, modify, and delete posts, comments, and reactions on your organization's behalf
// rw_ads
// Manage your advertising accounts
// r_basicprofile
// Use your basic profile including your name, photo, headline, and public profile URL
// r_organization_admin
// Retrieve your organization's pages and their reporting data (including follower, visitor and content analytics)

export async function handleLinkedinAdd() {
  const rootUrl = 'https://www.linkedin.com/oauth/v2/authorization?';
  const options = {
    response_type: 'code',
    client_id: LINKEDIN_CLIENT_ID,
    redirect_uri: LINKEDIN_REDIRECT_URI,
    state: 'contentcalender_linkedin_personal',
    scope: [
      'r_liteprofile',
      'r_basicprofile',
      // 'r_organization_social',
      // 'rw_organization_admin',
      // 'w_organization_social',
      'r_emailaddress',
      'w_member_social',
      // 'r_organization_admin',
    ].join(' '),
  };
  const params = new URLSearchParams(options);
  const authLink = `${rootUrl}${params.toString()}`;
  window.open(authLink, '_self');
  return '';
}

export async function handleLinkedinOrganizationAdd() {
  const rootUrl = 'https://www.linkedin.com/oauth/v2/authorization?';
  const options = {
    response_type: 'code',
    client_id: LINKEDIN_CLIENT_ID,
    redirect_uri: LINKEDIN_REDIRECT_URI,
    state: 'contentcalender_linkedin_organization',
    scope: [
      'r_liteprofile',
      'r_basicprofile',
      'r_organization_social',
      'rw_organization_admin',
      'w_organization_social',
      'r_emailaddress',
      'w_member_social',
      'r_organization_admin',
    ].join(' '),
  };
  const params = new URLSearchParams(options);
  const authLink = `${rootUrl}${params.toString()}`;
  window.open(authLink, '_self');
  return '';
}
