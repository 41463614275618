import { Dialog, Menu, Transition } from '@headlessui/react';
import {
  IconCalendarEvent,
  IconChartDots,
  IconHome2,
  IconHomeStats,
  IconMenu2,
  IconSettings,
  IconUserCircle,
  IconUserShare,
  IconX,
} from '@tabler/icons-react';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useDisclosure } from '~/hooks/useDisclosure';

import { useAuth } from '~/lib/auth';

type SideNavigationItem = {
  name: string;
  to: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  children: SideNavigationItem[];
};
const navigation = [
  { name: 'Dashboard', to: './dashboard', icon: IconHome2 },
  { name: 'Composer', to: './composer', icon: IconUserShare },
  { name: 'Calender', to: './calender', icon: IconCalendarEvent },
  { name: 'Social Platforms', to: './social-platform', icon: IconUserShare },
  { name: 'Analytics', to: './analytics', icon: IconChartDots },
  { name: 'Campaign', to: './campaign', icon: IconHomeStats },
].filter(Boolean) as SideNavigationItem[];

const SideNavigation = () => {
  return (
    <div className="flex h-full flex-col justify-between">
      <div className="flex flex-col">
        {navigation.map((item, index) => (
          <NavLink
            end={index === 0}
            key={item.name}
            to={item.to}
            className={({ isActive }) =>
              clsx(
                isActive && 'bg-slate-700 shadow',
                'text-white hover:bg-gray-600 ',
                'group flex items-center rounded-md px-2 py-2 text-base font-medium',
              )
            }
          >
            <item.icon className="" />
            <span className="p-1" />
            <span>{item.name}</span>
          </NavLink>
        ))}
      </div>

      <div className="mt-auto flex flex-col">
        <NavLink
          to={'/settings'}
          className={({ isActive }) =>
            clsx(
              isActive ? 'shadow' : '',
              'text-white hover:bg-gray-600 ',
              'group flex items-center rounded-md px-2 py-2 text-base font-medium',
            )
          }
        >
          <IconSettings className="" />
          <span className="p-1" />
          <span>Settings</span>
        </NavLink>
      </div>
    </div>
  );
};

type UserNavigationItem = {
  name: string;
  to: string;
  onClick?: () => void;
};

const UserNavigation = () => {
  const { logout } = useAuth();

  const userNavigation = [
    { name: 'Your Profile', to: './profiles' },
    { name: 'Settings', to: './settings' },
    {
      name: 'Sign out',
      to: '',
      onClick: async () => {
        await logout();
        window.location.reload();
      },
    },
  ].filter(Boolean) as UserNavigationItem[];

  return (
    <Menu as="div" className="relative ml-3">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className="flex max-w-xs items-center rounded-full p-2 text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              <span className="sr-only">Open user menu</span>
              <IconUserCircle className="h-8 w-8 rounded-full" />
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={React.Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              {userNavigation.map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <Link
                      onClick={item.onClick}
                      to={item.to}
                      className={clsx(
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-500',
                      )}
                    >
                      {item.name}
                    </Link>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

type MobileSidebarProps = {
  sidebarOpen: boolean;
  close: () => void;
};

const MobileSidebar = ({ sidebarOpen, close }: MobileSidebarProps) => {
  const { key } = useLocation();
  useEffect(() => {
    if (sidebarOpen) close();
  }, [key]);
  return (
    <Transition.Root show={sidebarOpen} as={React.Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-40 flex  md:hidden"
        open={sidebarOpen}
        onClose={close}
      >
        <Transition.Child
          as={React.Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-primary bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={React.Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex w-full max-w-xs flex-1 flex-col bg-primary pb-4 pt-5">
            {/* <Transition.Child
              as={React.Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className=" pt-2">
                <button
                  className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() => setSidebarOpen(false)}
                >
                  <span className="sr-only">Close sidebar</span>
                  <IconX className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </Transition.Child> */}
            <div className="flex flex-shrink-0 items-center justify-between px-4">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.333333"
                  y="0.333333"
                  width="14.3175"
                  height="14.3175"
                  rx="7.15873"
                  fill="#3EF1DC"
                  stroke="#3EF1DC"
                  strokeWidth="0.666667"
                />
                <rect
                  x="0.333333"
                  y="17.3492"
                  width="14.3175"
                  height="14.3175"
                  rx="7.15873"
                  fill="#3EF1DC"
                  stroke="#3EF1DC"
                  strokeWidth="0.666667"
                />
                <rect
                  x="17.3492"
                  y="17.3492"
                  width="14.3175"
                  height="14.3175"
                  rx="7.15873"
                  fill="#3EF1DC"
                  stroke="#3EF1DC"
                  strokeWidth="0.666667"
                />
                <rect
                  x="20.7619"
                  y="3.74603"
                  width="7.49206"
                  height="7.49206"
                  rx="3.74603"
                  stroke="#3EF1DC"
                  strokeWidth="7.49206"
                  strokeDasharray="53.33 53.33"
                />
              </svg>
              <button
                className="ml-1 flex h-10 w-10 items-center justify-center rounded-full bg-primary focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                onClick={close}
              >
                <span className="sr-only">Close sidebar</span>
                <IconX className="h-6 w-6 text-white" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-5 h-0  flex-1 overflow-y-auto">
              <nav className="h-full space-y-1 px-2">
                <SideNavigation />
              </nav>
            </div>
          </div>
        </Transition.Child>
        <div className="w-14 flex-shrink-0" aria-hidden="true"></div>
      </Dialog>
    </Transition.Root>
  );
};

const Sidebar = () => {
  return (
    <div className="hidden md:flex md:flex-shrink-0">
      <div className="flex w-64 flex-col bg-primary">
        <div className="flex h-0 flex-1 flex-col">
          <div className="flex h-16 flex-shrink-0 items-center px-4 ">
            {/* <Logo /> */}
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.333333"
                y="0.333333"
                width="14.3175"
                height="14.3175"
                rx="7.15873"
                fill="#3EF1DC"
                stroke="#3EF1DC"
                strokeWidth="0.666667"
              />
              <rect
                x="0.333333"
                y="17.3492"
                width="14.3175"
                height="14.3175"
                rx="7.15873"
                fill="#3EF1DC"
                stroke="#3EF1DC"
                strokeWidth="0.666667"
              />
              <rect
                x="17.3492"
                y="17.3492"
                width="14.3175"
                height="14.3175"
                rx="7.15873"
                fill="#3EF1DC"
                stroke="#3EF1DC"
                strokeWidth="0.666667"
              />
              <rect
                x="20.7619"
                y="3.74603"
                width="7.49206"
                height="7.49206"
                rx="3.74603"
                stroke="#3EF1DC"
                strokeWidth="7.49206"
                strokeDasharray="53.33 53.33"
              />
            </svg>
          </div>
          <div className="flex flex-1 flex-col overflow-y-auto">
            <nav className="flex-1 space-y-1 bg-primary px-2 py-4">
              <SideNavigation />
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

type MainLayoutProps = {
  children: React.ReactNode;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  const { close, open, isOpen } = useDisclosure(false);

  return (
    <div className="flex h-screen overflow-hidden ">
      <MobileSidebar sidebarOpen={isOpen} close={close} />
      <Sidebar />
      <div className="flex w-0 flex-1 flex-col overflow-hidden">
        <div className="relative z-10 flex h-16 flex-shrink-0 bg-white shadow">
          <button
            className="px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
            onClick={open}
          >
            <span className="sr-only">Open sidebar</span>
            <IconMenu2 className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex flex-1 justify-end px-4">
            <div className="ml-4 flex items-center md:ml-6">
              <UserNavigation />
            </div>
          </div>
        </div>
        <main className="relative flex-1 overflow-y-auto focus:outline-none">
          {children}
        </main>
      </div>
    </div>
  );
};
