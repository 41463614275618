import { IconEye, IconEyeOff } from '@tabler/icons-react';
import clsx from 'clsx';
import { useState } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type InputFieldProps = FieldWrapperPassThroughProps & {
  type?: 'text' | 'email' | 'password' | 'number';
  className?: string;
  placeholder?: string;
  registration: Partial<UseFormRegisterReturn>;
};

export const InputField = (props: InputFieldProps) => {
  const {
    type = 'text',
    label,
    placeholder,
    className,
    registration,
    error,
  } = props;
  const [state, setState] = useState(type);
  function toogle() {
    setState(state === 'password' ? 'text' : 'password');
  }
  return (
    <FieldWrapper label={label} error={error}>
      <div className="flex items-center justify-end">
        <input
          type={state}
          placeholder={placeholder}
          className={clsx(
            'block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm',
            className,
          )}
          {...registration}
        />
        {type === 'password' && (
          <button
            type="button"
            onClick={toogle}
            className="absolute m-1 h-6 w-6"
          >
            {state === 'password' ? <IconEye /> : <IconEyeOff />}
          </button>
        )}
      </div>
    </FieldWrapper>
  );
};
