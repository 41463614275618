import {
  IconBrandFacebook,
  IconBrandLinkedin,
  IconBrandTwitter,
} from '@tabler/icons-react';

type ProfileAvatarProps = {
  src: string;
  alt: string;
  space: 'linkedin' | 'facebook' | 'twitter';
};

export function ProfileAvatar({ src, alt, space }: ProfileAvatarProps) {
  return (
    <span className="relative flex items-center  justify-center">
      <img
        className="rounded-full"
        src={src}
        alt={alt}
        width={50}
        height={50}
      />
      <div className="absolute bottom-0 right-0">
        {space === 'linkedin' && (
          <IconBrandLinkedin className="h-5 w-5" fill="white" />
        )}
        {space === 'facebook' && (
          <IconBrandFacebook className="h-5 w-5" fill="white" />
        )}
        {space === 'twitter' && (
          <IconBrandTwitter className="h-5 w-5 fill-blue-400 text-xs text-white" />
        )}
      </div>
    </span>
  );
}
