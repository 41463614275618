import { useQuery } from 'react-query';
import { axios } from '~/lib/axios';

export const createCampaign = (data: any) => {
  return axios.post('/campaign', data);
};

export async function getCampaign() {
  const { data } = await axios.get('/campaign');
  return data;
}

export const useCampaign = () => {
  return useQuery('campaign', getCampaign, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};
