import { Listbox, Transition } from '@headlessui/react';
import { IconCheckbox, IconCaretDown } from '@tabler/icons-react';
import { Dispatch, SetStateAction, Fragment } from 'react';

import { getFullName } from '~/utils/helper';
import { ProfileAvatar } from '../Elements/Avatar';

export function ProfileSelect({
  profiles,
  selected,
  setSelected,
}: {
  selected: any[];
  profiles: any[];
  setSelected: Dispatch<SetStateAction<any[]>>;
}) {
  return (
    <div className="relative">
      <Listbox value={selected} onChange={setSelected} multiple>
        <div className=" ">
          <Listbox.Button className="flex h-16 w-full cursor-default items-center justify-between rounded-lg bg-white p-2  text-left shadow-md sm:text-sm">
            <span className="flex gap-2 truncate">
              {selected.map((profile) => (
                <ProfileAvatar
                  key={profile._id}
                  space={profile.space}
                  src={profile.profile_pic}
                  alt={getFullName(profile.name) ?? ''}
                />
              ))}
            </span>
            <span className="pointer-events-none flex items-center justify-center pr-2">
              <IconCaretDown
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className=" bg-primary-50 z-10 mt-1 max-h-60 overflow-auto rounded-md  text-base shadow-lg  sm:text-sm">
              {profiles.map((profile, profileIdx) => (
                <Listbox.Option
                  key={profileIdx}
                  className={({ active }) =>
                    ` cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? 'bg-primary-100 text-amber-900' : 'text-gray-900'
                    }`
                  }
                  value={profile}
                >
                  {({ selected }) => (
                    <div className="flex  items-center">
                      {selected ? (
                        <span className="  flex items-center p-1.5  text-amber-600">
                          <IconCheckbox
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </span>
                      ) : (
                        <span className="p-4 " />
                      )}
                      <span
                        className={`ml-4 flex items-center justify-around gap-4 truncate ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        <ProfileAvatar
                          key={profile._id}
                          space={profile.space}
                          src={profile.profile_pic}
                          alt={getFullName(profile.name) ?? ''}
                        />
                        {getFullName(profile.name)}
                      </span>
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
