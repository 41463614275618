import { Layout, LoginForm } from '../components';

export const Login = () => {
  return (
    <>
      <Layout title="Login Page">
        <LoginForm onSuccess={() => window.location.reload()} />
      </Layout>
    </>
  );
};
