import { IconCalendar, IconPlus } from '@tabler/icons-react';
import { FC, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { z } from 'zod';
import { Button } from '~/components/Elements/Button';
import { Modal } from '~/components/Elements/Modal';
import { ProfileAvatar } from '~/components/Elements/Avatar';
import { Form, InputField } from '~/components/Form';
import { PreviewCard, ProfileSelect } from '~/components/Global';
import { ContentLayout } from '~/components/Layouts/ContentLayout';
import { useDisclosure } from '~/hooks/useDisclosure';
import { useProfiles } from '~/hooks/useProfile';
import { getFullName } from '~/utils/helper';
import { createCampaign, useCampaign } from '../api/campaign';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '~/components/ui/accordion';

const schema = z.object({
  topic: z.string().min(1, 'Required'),
  days: z.string().min(1, 'Required'),
});

export type CampaignPayload = z.infer<typeof schema>;

export const Campaign: FC = () => {
  const { close, isOpen, open } = useDisclosure(false);

  const { data, isFetched } = useProfiles();
  const { data: campaigns, isLoading, refetch } = useCampaign();
  console.log('🚀 ~ file: Campaign.tsx:27 ~ campaigns:', campaigns);

  const [selected, setSelected] = useState([] as any[]);
  console.log('🚀 ~ file: Campaign.tsx:25 ~ selected:', selected);

  const handleSubmit: SubmitHandler<CampaignPayload> = async (value) => {
    const selectedProfiles = selected.map((prof) => prof._id);
    if (selected.length === 0) return toast('No Profile Selected');
    const { data, status } = await createCampaign({
      ...value,
      selected: selectedProfiles,
    });
    close();
    refetch();
    console.log('🚀 ~ file: Campaign.tsx:36 ~ data, status:', data, status);
  };

  return (
    <ContentLayout title="Campaign">
      <section className="flex w-full flex-col">
        <div className="">
          <Button onClick={open} className="ml-auto" startIcon={<IconPlus />}>
            Create
          </Button>
        </div>
        <div className="mt-8">
          <Accordion type="multiple" className="w-full ">
            {Array.isArray(campaigns) &&
              campaigns.map((campaign) => (
                <AccordionItem
                  key={campaign._id}
                  value={campaign._id}
                  className="my-2 rounded-lg bg-purple-300 p-10"
                >
                  <AccordionTrigger>
                    <div className="mt-2 flex w-full  gap-8 ">
                      {campaign.topic}
                    </div>
                  </AccordionTrigger>
                  <AccordionContent className="">
                    <div>
                      <div className="flex w-full gap-8 ">
                        <div className="flex flex-col ">
                          <span>days</span>
                          <p> {campaign.days}</p>
                        </div>
                        <div className="flex flex-col">
                          <span>profiles</span>
                          <div>
                            {campaign.profiles?.map((profile: any) => (
                              <ProfileAvatar
                                key={profile._id}
                                space={profile.space}
                                src={profile.profile_pic}
                                alt={getFullName(profile.name) ?? ''}
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="mt-4">
                        {' '}
                        <div className="mt-8 flex gap-2 overflow-x-scroll">
                          {Array.isArray(campaign.campaign_parsed) &&
                            campaign.campaign_parsed.map((post: any) => (
                              <div className="flex w-full" key={post.title}>
                                <PreviewCard
                                  isMutable={false}
                                  post={{
                                    createdAt: new Date(),
                                    profile: campaign.profiles[0],
                                    rawPayload: {
                                      content: post.socialPost,
                                    },
                                  }}
                                  space={'linkedin'}
                                />
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </AccordionContent>
                </AccordionItem>
              ))}
          </Accordion>
        </div>
      </section>

      <Modal
        close={close}
        isOpen={isOpen}
        title={'Create a New Campaign'}
        icon={<IconCalendar />}
        showCancel={false}
      >
        <div className="mt-4 flex w-full flex-col items-center ">
          <Form<CampaignPayload, typeof schema>
            onSubmit={handleSubmit}
            schema={schema}
            className="w-full"
          >
            {({ register, formState }) => (
              <>
                <InputField
                  type="text"
                  label="Topic"
                  error={formState.errors['topic']}
                  registration={register('topic')}
                />
                <InputField
                  type="number"
                  label="Number of Days"
                  error={formState.errors['days']}
                  registration={register('days')}
                />
                {isFetched && (
                  <ProfileSelect
                    profiles={data}
                    selected={selected}
                    setSelected={setSelected}
                  />
                )}
                <div className="flex gap-4">
                  <Button
                    type="button"
                    variant="inverse"
                    onClick={close}
                    className="ml-auto inline-flex w-full justify-center rounded-md border focus:ring-1 focus:ring-indigo-500 focus:ring-offset-1 sm:mt-0 sm:w-auto sm:text-sm"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    className=" inline-flex w-full justify-center rounded-md border focus:ring-1 focus:ring-indigo-500 focus:ring-offset-1 sm:mt-0 sm:w-auto sm:text-sm"
                  >
                    Submit
                  </Button>
                </div>
              </>
            )}
          </Form>
        </div>
      </Modal>
    </ContentLayout>
  );
};
