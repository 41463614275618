import { ContentLayout } from '~/components/Layouts/ContentLayout';

export const Dashboard = () => {
  return (
    <ContentLayout title="Dashboard">
      <section>
        <div>Dashboard page</div>
      </section>
    </ContentLayout>
  );
};
