import { memoize } from 'lodash';

export function isDefinedArray(payload: unknown) {
  if (!payload || !Array.isArray(payload)) return false;
  return true;
}

export async function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const getFullName = memoize(
  ({
    first_name = 'undefined',
    last_name = 'undefined',
    full_name,
  }: {
    first_name: string;
    last_name: string;
    full_name?: string;
  }) => {
    if (full_name) return `${full_name}`;
    return `${first_name} ${last_name}`;
  },
);
