import { create } from 'zustand';

type ContentState = {
  content: string;
  setContent: (value: string) => void;
};

export const usePreviewData = create<ContentState>((set) => ({
  content: '',
  setContent: (value) => set(() => ({ content: value })),
}));
