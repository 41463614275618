import { EventContentArg, EventInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!

import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { IconEdit, IconTrash } from '@tabler/icons-react';

import clsx from 'clsx';
import { FC, useState } from 'react';
import DateTimePicker from 'react-datetime-picker';
import { ProfileAvatar } from '~/components/Elements/Avatar';
import { Button } from '~/components/Elements/Button';
import { Drawer } from '~/components/Elements/Drawer';
import { DropDown, DropDownType } from '~/components/Elements/DropDown';
import { Modal } from '~/components/Elements/Modal';

import { ContentLayout } from '~/components/Layouts/ContentLayout';
import { useDisclosure } from '~/hooks/useDisclosure';
import { useProfiles } from '~/hooks/useProfile';
import { getFullName } from '~/utils/helper';
import { lazyImport } from '~/utils/lazyImport';

const { default: FullCalendar } = lazyImport(
  () => import('@fullcalendar/react'),
  'default',
);

function renderEventContent(eventContent: EventContentArg) {
  return (
    <div
      className={clsx(
        'flex w-full items-center justify-between gap-2 rounded bg-purple-500 p-1',
      )}
    >
      <b className="text-white">{eventContent.timeText}</b>
      <p>{eventContent.event.title}</p>
    </div>
  );
}

const todayStr = new Date().toISOString().replace(/T.*$/, ''); // YYYY-MM-DD of today

const events: EventInput[] = [
  {
    id: 'asdqsdasdasdas',
    title: 'All-day event',
    start: todayStr,
  },
  {
    id: 'asdqsdasd',
    title: 'Timed event',
    start: todayStr + 'T12:00:00',
  },
];

const profileOptions: DropDownType['options'] = [
  {
    icon: <IconEdit className="mr-2 h-5 w-5" aria-hidden="true" />,
    text: 'Edit',
    handler: ({ profile }) => {
      console.log('edit called', profile);
    },
  },
  {
    icon: <IconTrash className="mr-2 h-5 w-5" aria-hidden="true" />,
    text: 'Remove',
    handler: ({ profile }) => {
      console.log('remove called', profile);
    },
  },
];

export const Calender: FC = () => {
  const { close, open, isOpen } = useDisclosure();

  const [activeDate, setActiveDate] = useState('');

  const { data, isFetched } = useProfiles();

  return (
    <ContentLayout title="Calender">
      <section>
        <div>Calender page</div>

        <FullCalendar
          height={'auto'}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay',
          }}
          initialView="dayGridMonth"
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          weekends={true}
          events={events}
          dateClick={({ date, dateStr }: DateClickArg) => {
            console.log('date click', date, dateStr);
            setActiveDate(dateStr);
            open();
          }}
          eventContent={renderEventContent} // custom render function
          eventClick={(rest) => console.info('event click:', rest.event.id)}
          eventsSet={(...rest) => console.info('event set:', rest)} // called after events are initialized/added/changed/removed
        />
      </section>
      <Drawer
        isOpen={isOpen}
        onClose={close}
        title={activeDate}
        renderFooter={() => (
          <>
            <Button variant="inverse" size="sm" onClick={close}>
              Cancel
            </Button>
            <Button variant="primary" size="sm" onClick={close}>
              Submit
            </Button>
            {/* {submitButton} */}
          </>
        )}
      >
        <div className="flex h-full w-full flex-col gap-4">
          <div className="ml-auto">
            <Button>Create</Button>
          </div>
          {isFetched &&
            Array.isArray(data) &&
            data.map((profile) => (
              <div
                key={profile._id}
                className="flex items-center gap-4 rounded-lg bg-blue-100 p-4"
              >
                <ProfileAvatar
                  key={profile._id}
                  space={profile.space}
                  src={profile.profile_pic}
                  alt={getFullName(profile.name) ?? ''}
                />
                <span>post content</span>
                <span>{new Date().toISOString()}</span>
                <div className="ml-auto">
                  <DropDown options={profileOptions} profile={profile._id} />
                </div>
              </div>
            ))}
        </div>
      </Drawer>
    </ContentLayout>
  );
};
