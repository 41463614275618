import { GOOGLE_CLIENT_ID, GOOGLE_REDIRECT_URI } from '~/config';
import { axios } from '~/lib/axios';

export function handleGoogleLogin() {
  const rootUrl = 'https://accounts.google.com/o/oauth2/auth?';
  const options = {
    client_id: GOOGLE_CLIENT_ID,
    response_type: 'code',
    redirect_uri: GOOGLE_REDIRECT_URI,
    prompt: 'consent',
    access_type: 'offline',
    scope: [
      'https://www.googleapis.com/auth/userinfo.profile',
      'https://www.googleapis.com/auth/userinfo.email',
    ].join(' '),
  };
  const params = new URLSearchParams(options);
  const authLink = `${rootUrl}${params.toString()}`;
  window.open(authLink, '_self');
  return '';
}

export const loginWithGoogle = async (code: string) => {
  const params = new URLSearchParams({
    code,
  });
  console.log('frontend called', code);
  const url = '/social/google/callback?' + params.toString();
  const { status } = await axios.get(url);
  if (status !== 200) throw new Error('something went wring');
  window.location.assign('/');
};
