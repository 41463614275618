import { Switch } from '@headlessui/react';
import {
  IconArrowAutofitRight,
  IconClock,
  IconPhoto,
  IconSend,
  IconWriting,
} from '@tabler/icons-react';
import { useState } from 'react';
import DateTimePicker from 'react-datetime-picker';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { Button } from '~/components/Elements/Button';
import { Fallback } from '~/components/Elements/Fallback';
import { ProfileSelect } from '~/components/Global';
import { ContentLayout } from '~/components/Layouts/ContentLayout';
import { useDisclosure } from '~/hooks/useDisclosure';
import { useProfiles } from '~/hooks/useProfile';
import { createPost } from '../api/post';
import { PreviewCard } from '../component';
import { usePreviewData } from '../hook/usePreviewData';

export function Composer() {
  const { data: profiles, isFetched, isLoading } = useProfiles();
  const queryClient = useQueryClient();
  const [value, onChange] = useState(new Date());

  const handleDateChange = (date: any) => onChange(date);

  const { close, open, isOpen, toggle } = useDisclosure();

  const [selected, setSelected] = useState([] as any[]);
  const [loading, setLoading] = useState(false);

  const { content, setContent } = usePreviewData();

  const resetContent = () => setContent('');

  const handleSubmit = async () => {
    try {
      setLoading(true);

      if (!(Array.isArray(selected) && content)) {
        return toast('Content is Empty');
      }
      const payload: any = {
        text: content,
      };

      if (isOpen) {
        const valueParsedDate = new Date(value).getTime();
        if (valueParsedDate <= new Date().getTime())
          return toast.error('Cant schedule back in time');
        payload['schedule'] = value;
      }
      console.log('payload', payload);

      const { data, status } = await createPost({
        ids: selected.map((profile) => profile._id),
        payload,
      });
      if (status === 200) {
        toast.success('Post Uploaded Successfully');
        resetContent();
        queryClient.refetchQueries('recent_post');
        queryClient.refetchQueries('scheduled_post');
        if (isOpen) queryClient.refetchQueries('schedule_post');
      }
      console.log('data', data, status);
    } catch (error) {
      toast.error('Post Creation Went Wrong');
      console.error('error ', error);
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) return <Fallback />;

  return (
    <ContentLayout title="Composer">
      <div className=" ">
        <div className="flex w-full table-fixed items-center justify-between gap-4 rounded-lg bg-blue-200  p-4">
          <div className="ml-auto flex gap-4 ">
            {isOpen ? (
              <Button
                isLoading={loading}
                disabled={loading}
                startIcon={<IconClock className="h-4 w-4" />}
                onClick={handleSubmit}
              >
                Schedule
              </Button>
            ) : (
              <Button
                startIcon={<IconSend className="h-4 w-4" />}
                isLoading={loading}
                disabled={loading}
                onClick={handleSubmit}
              >
                Post now
              </Button>
            )}
          </div>
        </div>
        <div className="mt-4 flex h-[77vh] flex-col gap-4 rounded sm:flex-row ">
          <div className="w-full overflow-scroll rounded-lg bg-gray-200  p-8  ">
            <div className="">
              <div className="flex items-center gap-4 py-2 ">
                <IconArrowAutofitRight />
                Post To
              </div>
              {isFetched && (
                <ProfileSelect
                  setSelected={setSelected}
                  selected={selected}
                  profiles={profiles}
                />
              )}
              <div className="flex w-full flex-col justify-center ">
                <div className="my-10 flex flex-col justify-center">
                  <div className="flex items-center justify-between gap-4 py-2 ">
                    <div className="flex items-center gap-4 py-2">
                      <IconWriting />
                      Schedule
                    </div>
                    <div className="flex items-center  justify-between">
                      <Switch
                        id="toggle-schedule"
                        checked={isOpen}
                        onChange={toggle}
                        className={`${
                          isOpen ? 'bg-primary-600' : 'bg-primary-300'
                        }
                    relative ml-10 inline-flex h-[38px] w-[74px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                      >
                        <span className="sr-only">Use setting</span>
                        <span
                          aria-hidden="true"
                          className={`${
                            isOpen ? 'translate-x-9' : 'translate-x-0'
                          }
                      pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                        />
                      </Switch>
                    </div>
                  </div>
                </div>

                {isOpen && (
                  <div className="w-full">
                    <DateTimePicker onChange={handleDateChange} value={value} />
                  </div>
                )}
              </div>
              <div className="my-10 flex flex-col">
                <div className="flex items-center gap-4 py-2 ">
                  <IconWriting />
                  Content
                </div>
                <textarea
                  value={content}
                  onChange={(event) => {
                    setContent(event.target.value);
                  }}
                  name="content"
                  rows={6}
                  placeholder="Post Content here"
                  className="w-full resize-y rounded-md p-2"
                ></textarea>
              </div>
            </div>
          </div>
          <div className="flex h-full w-full flex-col items-center justify-center overflow-y-auto rounded-lg  bg-gray-400 ">
            {selected.length ? (
              <div className="h-full w-2/3 py-4">
                {selected.map((profile) => (
                  <PreviewCard
                    space={profile.space}
                    key={profile._id}
                    post={{
                      createdAt: new Date(),
                      profile,
                      rawPayload: {
                        content,
                      },
                    }}
                  />
                ))}
              </div>
            ) : (
              <div className="flex rounded-md bg-white p-10">
                Select profiles To Post
              </div>
            )}
          </div>
        </div>
      </div>
    </ContentLayout>
  );
}
