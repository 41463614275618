import {
  IconBrandLinkedin,
  IconBuilding,
  IconEdit,
  IconTrash,
  IconUserCircle,
} from '@tabler/icons-react';
import { useCallback, useMemo } from 'react';
import { ProfileAvatar } from '~/components/Elements/Avatar';
import { Button } from '~/components/Elements/Button';
import { DropDown, DropDownType } from '~/components/Elements/DropDown';
import { Modal } from '~/components/Elements/Modal';
import { useDisclosure } from '~/hooks/useDisclosure';
import { useProfiles } from '~/hooks/useProfile';
import { useAuth } from '~/lib/auth';
import { getFullName } from '~/utils/helper';
import {
  handleLinkedinAdd,
  handleLinkedinOrganizationAdd,
} from '../api/handleSocialAdd';

type BaseSocialProps = {
  title: string;
  handler?: () => void;
  icon: JSX.Element;
};

type SocialProps = {
  site: 'facebook' | 'instagram' | 'twitter' | 'linkedin';
  accountType?: string;
  workflow?: Array<BaseSocialProps>;
  icon: JSX.Element;
  disabled?: boolean;
} & BaseSocialProps;

const socials: SocialProps[] = [
  {
    title: 'Linkedin',
    site: 'linkedin',
    accountType: 'Personal Page or LinkedIn Page',
    icon: <IconBrandLinkedin />,
    workflow: [
      {
        title: 'Personal',
        icon: <IconUserCircle className="h-8 w-8 " />,

        handler: handleLinkedinAdd,
      },
      {
        title: 'Organization',
        icon: <IconBuilding className="h-8 w-8 " />,

        handler: handleLinkedinOrganizationAdd,
      },
    ],
  },
  // {
  //   title: 'Twitter',
  //   site: 'twitter',
  //   icon: <IconBrandTwitter />,
  // },
  // {
  //   title: 'Facebook',
  //   site: 'facebook',
  //   disabled: true,
  //   accountType: 'Business Accounts',
  //   icon: <IconBrandFacebook />,
  // },
  // {
  //   title: 'Instagram',
  //   site: 'instagram',
  //   disabled: true,
  //   accountType: 'Business or Professional Accounts',
  //   icon: <IconBrandInstagram />,
  // },
  // {
  //   title: 'Google Business Profile',
  //   site: 'googlebusiness',
  //   disabled: true,
  //   icon: <IconBrandGoogle />,
  // },
];

const profileOptions: DropDownType['options'] = [
  {
    icon: <IconEdit className="mr-2 h-5 w-5" aria-hidden="true" />,
    text: 'Edit',
    handler: ({ profile }) => {
      console.log('edit called', profile);
    },
  },
  {
    icon: <IconTrash className="mr-2 h-5 w-5" aria-hidden="true" />,
    text: 'Remove',
    handler: ({ profile }) => {
      console.log('remove called', profile);
    },
  },
];

function SocialCard({ card }: { card: SocialProps }) {
  const { refetch, isAuth } = useAuth();

  const { data } = useProfiles();

  const { close, isOpen, open } = useDisclosure(false);

  const activeProfiles = useMemo(() => {
    if (Array.isArray(data)) {
      const profiles: any[] | undefined = [];
      data.forEach(
        (profile) => profile.space === card.site && profiles.push(profile),
      );
      return profiles;
    }
    return undefined;
  }, [card.site, data]);

  const handleOnClick = useCallback(async () => {
    if (Array.isArray(card.workflow) && card.workflow.length > 0) open();
  }, []);

  return (
    <>
      <div className="h-fit p-4 shadow" key={card.site}>
        <div className="flex">
          <div className="flex items-center gap-4">
            {card.icon}
            <span>{card.title}</span>
          </div>
          <Button
            size="sm"
            disabled={card.disabled}
            onClick={handleOnClick}
            className="ml-auto p-0"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-plus"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M12 5l0 14"></path>
              <path d="M5 12l14 0"></path>
            </svg>
          </Button>
        </div>
        <p className="border-b-2 pb-2 text-sm font-thin">
          <span className="mx-10 my-6">{card.accountType ?? ''}</span>
        </p>
        {Array.isArray(activeProfiles) &&
          activeProfiles.map((active) => (
            <div
              key={active._id}
              className="m-1 flex items-center justify-around gap-4 rounded-md bg-red-300 p-4"
            >
              {active.profile_pic && (
                <ProfileAvatar
                  src={active.profile_pic}
                  space={active.space}
                  alt={getFullName(active.name)}
                />
              )}
              <p>{getFullName(active.name)}</p>
              <DropDown options={profileOptions} profile={active._id} />
            </div>
          ))}
      </div>
      <Modal
        close={close}
        isOpen={isOpen}
        title={card.title + ' workflow'}
        icon={<IconBrandLinkedin />}
        // confirmButton={
        //   <Button
        //     type="button"
        //     isLoading={false}
        //     variant="inverse"
        //     className="inline-flex w-full justify-center rounded-md border focus:ring-1 focus:ring-indigo-500 focus:ring-offset-1 sm:mt-0 sm:w-auto sm:text-sm"
        //     onClick={() => {}}
        //   >
        //     Resend Mail
        //   </Button>
        // }
      >
        <div className="mt-4 flex w-full flex-col items-center justify-start gap-4">
          {card.workflow?.map((workflow) => (
            <div key={workflow.title} className=" flex w-full items-center ">
              {workflow.icon}
              <span className="ml-auto">Add {workflow.title} Account</span>
              <Button
                className="ml-auto"
                onClick={async () => {
                  await refetch();
                  if (isAuth) workflow.handler?.();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-plus"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M12 5l0 14"></path>
                  <path d="M5 12l14 0"></path>
                </svg>
              </Button>
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
}
export function ConnectionCard() {
  return (
    <div className="grid w-full grid-cols-1 gap-4 rounded  md:grid-cols-2 lg:grid-cols-3">
      {socials.map((card, index) => (
        <SocialCard key={card.title + index.toString()} card={card} />
      ))}
    </div>
  );
}
