import { IconBrandGoogle } from '@tabler/icons-react';
import { FC, useState } from 'react';
import { z } from 'zod';

import { Button } from '~/components/Elements/Button';
import { Link } from '~/components/Elements/Link';
import { Form, InputField } from '~/components/Form';
import {
  LoginCredentialsDTO,
  loginWithEmailAndPassword,
} from '~/features/auth/api/login';
import { handleGoogleLogin } from '../api/google';

const schema = z.object({
  email: z.string().min(1, 'Required'),
  password: z.string().min(1, 'Required'),
});

type LoginValues = {
  email: string;
  password: string;
};

type LoginFormProps = {
  onSuccess: () => void;
};

export const LoginForm: FC<LoginFormProps> = ({ onSuccess }) => {
  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmit(values: LoginCredentialsDTO) {
    setIsLoading(true);
    const { status } = await loginWithEmailAndPassword(values);

    if (status === 200) {
      onSuccess();
    }
    setIsLoading(false);
  }
  return (
    <section className="">
      <Form<LoginValues, typeof schema> onSubmit={handleSubmit} schema={schema}>
        {({ register, formState }) => (
          <>
            <InputField
              type="email"
              label="Email Address"
              error={formState.errors['email']}
              registration={register('email')}
            />
            <InputField
              type="password"
              label="Password"
              error={formState.errors['password']}
              registration={register('password')}
            />
            <div>
              <Button
                disabled={isLoading}
                isLoading={isLoading}
                type="submit"
                className="w-full"
              >
                Log in
              </Button>
            </div>
          </>
        )}
      </Form>

      <div className="mt-2 flex items-center justify-end">
        <div className="text-sm">
          <Link
            to="/register"
            className="font-medium text-blue-600 hover:text-blue-500"
          >
            Register
          </Link>
        </div>
      </div>
      <div className="w-full">
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-200" />
          </div>
          <div className="relative flex justify-center text-sm font-medium leading-6">
            <span className="bg-white px-6 text-gray-900">
              Or continue with
            </span>
          </div>
        </div>

        <div className="mt-6 flex w-full gap-4">
          <Button
            className="w-full"
            onClick={() => {
              handleGoogleLogin();
            }}
            startIcon={<IconBrandGoogle />}
          >
            <span className="text-sm font-semibold leading-6">Google</span>
          </Button>
        </div>
      </div>
    </section>
  );
};
