import { RouteObject, useRoutes } from 'react-router-dom';

import { Spinner } from '~/components/Elements/Spinner';
import { useAuth } from '~/lib/auth';
import { protectedRoutes } from '~/routes/protected';
import { publicRoutes } from '~/routes/public';

export const AppRoutes = () => {
  const auth = useAuth();

  const loadingRoute: RouteObject[] = [
    {
      path: '*',
      element: (
        <div className="flex h-screen w-screen items-center justify-center">
          <Spinner size="xl" />
        </div>
      ),
    },
  ];

  const userRoutes = auth.isAuth ? protectedRoutes : publicRoutes;

  const allRoutes =
    auth.isLoading || auth.isFetching || auth.isRefetching
      ? loadingRoute
      : [...userRoutes];

  const element = useRoutes(allRoutes);

  return <>{element}</>;
};
