import { FC } from 'preact/compat';
import { ContentLayout } from '~/components/Layouts/ContentLayout';
import { useProfiles } from '~/hooks/useProfile';
import { ConnectionCard } from '../components';

export const SocialPlatform: FC = () => {
  const { data } = useProfiles();

  console.log('data', data);
  return (
    <ContentLayout title="Social Platforms">
      <div className="bg-primary-50 mb-6 rounded p-10 text-lg font-bold">
        <h1>Connect Social Accounts</h1>
      </div>
      <ConnectionCard />
    </ContentLayout>
  );
};
