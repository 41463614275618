import { Suspense } from 'react';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';

import { Spinner } from '~/components/Elements/Spinner';
import { MainLayout } from '~/components/Layouts/MainLayout';
import { Calender } from '~/features/calender';
import { Campaign } from '~/features/campaign';
import { Composer } from '~/features/composer';
import { Dashboard } from '~/features/dashboard';
import { Settings } from '~/features/settings';
import { SocialPlatform } from '~/features/social-platform';
import { Profile } from '~/features/user';

const App = () => {
  return (
    <MainLayout>
      <Suspense
        fallback={
          <div className="flex h-full w-full items-center justify-center">
            <Spinner size="xl" />
          </div>
        }
      >
        <div className="h-full w-full p-4">
          <Outlet />
        </div>
      </Suspense>
    </MainLayout>
  );
};

export const protectedRoutes: RouteObject[] = [
  {
    path: '*',
    element: <App />,
    children: [
      // { path: '/discussions/*', element: <DiscussionsRoutes /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'composer', element: <Composer /> },
      { path: 'settings', element: <Settings /> },
      { path: 'calender', element: <Calender /> },
      { path: 'social-platform', element: <SocialPlatform /> },
      { path: 'campaign', element: <Campaign /> },
      { path: 'profiles', element: <Profile /> },
      { index: true, element: <Navigate to="dashboard" /> },
      { path: '*', element: <Navigate to="dashboard" /> },
    ],
  },
];
