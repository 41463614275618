import { formatDistanceToNow } from 'date-fns';
import { ProfileAvatar } from '~/components/Elements/Avatar';

import { getFullName } from '~/utils/helper';
import { usePreviewData } from '../hook/usePreviewData';

type LinkedinPost = {
  profile: any;
  createdAt: Date;
  // payload: any;
  rawPayload: {
    content: string;
  };
};

export function LinkedinPreviewCard({ post }: { post: LinkedinPost }) {
  const { profile } = post;
  const { content, setContent } = usePreviewData();

  return (
    <div className="flex w-full items-center justify-center  py-2  ">
      <div className="w-full rounded-lg border bg-white p-4 shadow-sm ">
        <div className="flex items-center">
          {profile && profile.profile_pic && (
            <ProfileAvatar
              alt={getFullName(profile.name)}
              space={profile.space}
              src={profile.profile_pic}
            />
          )}

          <div className="ml-2">
            <div className="text-sm ">
              <span className="font-semibold">{getFullName(profile.name)}</span>
              <span className="text-gray-500"> • 1st</span>
            </div>
            <div className="text-xs text-gray-500 ">
              {profile.description && profile.description.length > 50
                ? profile.description.substring(0, 45) + '...'
                : profile.description}
            </div>
            <div className="flex text-xs text-gray-500">
              <span className="inline-block">
                {formatDistanceToNow(new Date(post.createdAt))} • Edited •{' '}
              </span>
              <svg
                className="ml-1 inline-block fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                data-supported-dps="16x16"
                fill="currentColor"
                width="16"
                height="16"
                focusable="false"
              >
                <path d="M8 1a7 7 0 107 7 7 7 0 00-7-7zM3 8a5 5 0 011-3l.55.55A1.5 1.5 0 015 6.62v1.07a.75.75 0 00.22.53l.56.56a.75.75 0 00.53.22H7v.69a.75.75 0 00.22.53l.56.56a.75.75 0 01.22.53V13a5 5 0 01-5-5zm6.24 4.83l2-2.46a.75.75 0 00.09-.8l-.58-1.16A.76.76 0 0010 8H7v-.19a.51.51 0 01.28-.45l.38-.19a.74.74 0 01.68 0L9 7.5l.38-.7a1 1 0 00.12-.48v-.85a.78.78 0 01.21-.53l1.07-1.09a5 5 0 01-1.54 9z"></path>
              </svg>
            </div>
          </div>
        </div>
        <textarea
          value={content}
          onChange={(event) => {
            setContent(event.target.value);
          }}
          name="content"
          rows={6}
          placeholder="Post Content"
          className="w-full resize-y rounded-md p-2 outline-none"
        ></textarea>
        <div className="mt-3 flex items-center text-xs text-gray-500">
          <img
            alt=" sa"
            className="mr-0.5"
            src="https://static-exp1.licdn.com/sc/h/d310t2g24pvdy4pt1jkedo4yb"
          />
          <img
            alt=" sa"
            className="mr-0.5"
            src="https://static-exp1.licdn.com/sc/h/5thsbmikm6a8uov24ygwd914f"
          />
          <img
            alt=" sa"
            className="mr-0.5"
            src="https://static-exp1.licdn.com/sc/h/7fx9nkd7mx8avdpqm5hqcbi97"
          />
          <span className="ml-1">47 • 26 comments</span>
        </div>
      </div>
    </div>
  );
}
