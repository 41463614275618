export const API_URL = process.env.REACT_APP_API_URL as string;

export const isProd = process.env.NODE_ENV === 'production';

export const GOOGLE_CLIENT_ID = process.env
  .REACT_APP_GOOGLE_CLIENT_ID as string;

export const GOOGLE_REDIRECT_URI = process.env
  .REACT_APP_GOOGLE_REDIRECT_URI as string;

export const LINKEDIN_CLIENT_ID = process.env
  .REACT_APP_LINKEDIN_CLIENT_ID as string;

export const LINKEDIN_REDIRECT_URI = process.env
  .REACT_APP_LINKEDIN_REDIRECT_URI as string;
