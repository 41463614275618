import {
  IconAlertOctagon,
  IconClock,
  IconInfoCircle,
} from '@tabler/icons-react';
import { useRef } from 'react';
import { Button } from '../Button';
import { Dialog, DialogTitle } from '../Dialog';

export type ModalProps = {
  confirmButton?: React.ReactElement;
  children?: React.ReactNode;
  title: string;
  body?: string;
  cancelButtonText?: string;
  showCancel?: boolean;
  close: () => void;
  isOpen: boolean;
  icon?: React.ReactElement;
};

export function Modal({
  confirmButton,
  close,
  isOpen,
  cancelButtonText = 'Cancel',
  icon = <IconInfoCircle className="h-6 w-6 " aria-hidden="true" />,
  title,
  children,
  body,
  showCancel = true,
}: ModalProps) {
  const cancelButtonRef = useRef(null);

  return (
    <>
      <Dialog isOpen={isOpen} onClose={close} initialFocus={cancelButtonRef}>
        <div className="inline-block transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
          <div className="flex w-full items-center justify-center   text-center sm:flex sm:items-start ">
            {icon && (
              <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full  bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                {icon}
              </div>
            )}
            <div
              className=" flex w-full flex-col justify-center
             sm:ml-4 sm:mt-0 sm:text-left "
            >
              <DialogTitle
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                {title}
              </DialogTitle>
              {body && (
                <div className="mt-2">
                  <p className="text-sm text-gray-500">{body}</p>
                </div>
              )}
              {children}
            </div>
          </div>
          <div className="mt-4 flex justify-end space-x-2">
            {showCancel && (
              <Button
                type="button"
                variant="inverse"
                className="inline-flex w-full justify-center rounded-md border focus:ring-1 focus:ring-indigo-500 focus:ring-offset-1 sm:mt-0 sm:w-auto sm:text-sm"
                onClick={close}
                ref={cancelButtonRef}
              >
                {cancelButtonText}
              </Button>
            )}
            {confirmButton}
          </div>
        </div>
      </Dialog>
    </>
  );
}
